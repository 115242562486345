<template>
<div class="page-content w-100">
    <!-- start heading -->
    <div class="row">
        <div class="col-12">
            <h4><router-link to="/doctor/index" class="text-dark"><font-awesome-icon :icon="['fas', 'long-arrow-alt-left']"/></router-link><span class="text-color">Comments on post</span></h4>
        </div>
        <hr class="hr-border"/>
    </div>
    <!--  -->
    <div class="p-4">
        <div class="row">
            <div class="col-12 col-md-12 mx-auto">
                <div class="row">
                <div class="col-md-12 col-12">
                   <h4 class="text-20 font-weight-bold">Post</h4>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12 col-12 p-5 text-center">
                   <p class="text-center">Feed content</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-6">
                   <p class="text-start font-weight-bold">Sender's name</p>
                </div>
                <div class="col-md-6 col-6">
                   <p class="text-end">01/01/2022 14:00</p>
                </div>
            </div>

            <hr class="hr-border"/>

            <div class="row mt-3">
                <div class="col-md-12 col-12 text-center">
                   <p class="text-center">Date 1</p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 col-12">
                    <div class="border-color p-4">
                        <p class="text-color">Comment 1</p>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-6">
                   <p class="text-start font-weight-bold">name</p>
                </div>
                <div class="col-md-6 col-6">
                   <p class="text-end">01/01/2022 14:00</p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 col-12">
                    <div class="border-color p-4">
                        <p class="text-color">Comment 2</p>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-6">
                   <p class="text-start font-weight-bold">name</p>
                </div>
                <div class="col-md-6 col-6">
                   <p class="text-end">01/01/2022 16:00</p>
                </div>
            </div>

            <hr class="hr-border"/>

            <div class="row mt-3">
                <div class="col-md-12 col-12 text-center">
                   <p class="text-center">Date 2</p>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</template>